import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplePayButton from "./ApplePayButton";
import GooglePayButton from "./GooglePayButton";
import {
  initialState,
  CARD_ELEMENT_OPTIONS,
  monthlyInitialState,
} from "./constants";
import AddressForm from "./AddressForm";
import ContactForm from "./ContactForm";
import BillingForm from "./BillingForm";
import { useLocation } from "react-router-dom";
import createIntentPayment from "../services/raffle_payment_intent";
import createOtherIntentPayment from "../services/raffle_other_pay_payment_intent";
import { calculateTotalAmount } from "../services/paymentUtils";
import {
  handleCountryChange as handleCountryChangeUtil,
  handleBillingCountryChange as handleBillingCountryChangeUtil,
} from "../services/formUtils";
import TicketForm from "./TicketForm";
import TicketNameForm from "./TicketNameForm";
import CreditCardPayButton from "./CreditCardPayButton";
import getTicketLabel from "./ticketTextLabel";

const BuyTicketsForm = ({
  updateIsLoading,
  updateHasDonated,
  updateAmount,
  togglePayFees,
  payFees,
  updateFirstName,
  ticketOptions,
  updateTicketOption,
  selectedTicketOption,
  updateRegistrationURL,
  selectedTicketQuantityOption,
  ticketQuantityOptions,
  updateRaffleNumbers
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [applePay, setApplePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);

  const [creditCard, setCreditCard] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200); // Detect if initially on mobile

  const [ticketLabel, setTicketLabel] = useState("");
  const [titlePrefix, setTitlePrefix] = useState("");

  const getAmount = (selectedTicketQuantityOption, selectedTicketOption) => {
    return selectedTicketQuantityOption.value * selectedTicketOption.cost;
  };

  useEffect(() => {
    const isTest = window.location.hostname.includes('test.');

    if (isTest) {
      setTitlePrefix(<> [Test]</> );
    }

    if (!stripe || !elements) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: "CA",
      currency: "cad",
      requestPayerEmail: true,
      requestPayerName: true,
      requestPayerPhone: true,
      total: {
        label: "Donation to Big Sisters",
        amount: 30,
      },
    });

    const applePaySupported =
      window.ApplePaySession && window.ApplePaySession.canMakePayments();
    setApplePay(applePaySupported);

    if (applePaySupported) {
      setPaymentRequest(pr);
    }

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
        //setApplePay(result.applePay);
        setGooglePay(result.googlePay);
      }
    });

    pr.on("paymentmethod", async (e) => {
      await createOtherIntentPayment(
        stripe,
        updateFirstName,
        getAmount(selectedTicketQuantityOption, selectedTicketOption),
        payFees,
        e,
        toast,
        updateHasDonated,
        formData,
        selectedTicketQuantityOption.value,
        selectedTicketOption,
        updateRegistrationURL,
        updateRaffleNumbers
      );
    });
  }, [
    stripe,
    elements,
    payFees,
    updateFirstName,
    selectedTicketQuantityOption,
    selectedTicketOption,
    updateRegistrationURL,
    formData,
  ]);

  const updatePaymentRequestInstance = async (event) => {
    paymentRequest.update({
      country: "CA",
      currency: "cad",
      total: {
        label: "Purchase Tickets to Big Sisters' Event",
        amount: parseInt(
          calculateTotalAmount(
            getAmount(selectedTicketQuantityOption, selectedTicketOption),
            payFees,
            formData
          ) * 100
        ),
      },
    });
  };

  const toggleValue = (e, label) => {
    console.log(e);
    console.log(label);

    setFormData({
      ...formData,
      [label]: !formData[label],
    });
  };

  const handleInputChange = (event) => {
    if (event.target.id === "amount") {
      updateAmount(event.target.value);
    } else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChoiceUpdate = (event, label) => {
    const { checked } = event.target;
    setFormData({
      ...formData,
      [label]: checked,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Something went wrong. Please refresh the page.");
      updateIsLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);

    await createIntentPayment(
      stripe,
      cardElement,
      updateFirstName,
      getAmount(selectedTicketQuantityOption, selectedTicketOption),
      payFees,
      formData,
      updateHasDonated,
      toast,
      setFormData,
      updateIsLoading,
      selectedTicketQuantityOption.value,
      selectedTicketOption,
      updateRegistrationURL,
      updateRaffleNumbers
    );
  };

  const handleCountryChange = (selectedOption) => {
    handleCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingCountryChange = (selectedOption) => {
    handleBillingCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingProvinceChange = (selectedOption) => {
    setFormData({ ...formData, billingSelectedProvince: selectedOption });
  };

  const handleProvinceChange = (selectedOption) => {
    setFormData({ ...formData, selectedProvince: selectedOption });
  };

  const handleRealButtonClick = () => {
    updatePaymentRequestInstance();
    paymentRequest.show();
  };

  const renderGooglePaymentMethodLabel = () => {
    if (applePay) {
      if (!isMobile) {
        return (
          <label htmlFor="GooglePay">
            <span style={{ color: "white" }}>Google Pay</span>
          </label>
        );
      }
    } else {
      return <label htmlFor="GooglePay">Payment Method:</label>;
    }

    return <></>;
  };

  const renderCreditCardPaymentMethodLabel = () => {
    if (applePay || googlePay) {
      if (!isMobile) {
        return (
          <label htmlFor="CreditCardPay">
            <span style={{ color: "white" }}>Credit Card</span>
          </label>
        );
      }
    } else {
      return <label htmlFor="CreditCardPay">Payment Method:</label>;
    }

    return <></>;
  };

  useEffect(() => {
    const fetchTicketLabel = async () => {
      try {
        const label = await getTicketLabel();
        setTicketLabel(label);
      } catch (err) {
        console.log("Failed to fetch ticket label.");
      }
    };

    fetchTicketLabel();
  }, []);

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}>
          <h4 style={{ color: "#2dccd3" }}>
            Big Sisters’ Soirée Raffle <i>presented by Scotiabank</i>{titlePrefix}
          </h4>
          <p style={{ color: "#2dccd3", fontWeight: "bold" }}>{ticketLabel}</p>
          <div className="row  text-light">
            {!creditCard ? (
              <>
                <TicketForm
                  handleInputChange={handleInputChange}
                  formData={formData}
                  options={ticketOptions}
                  selectedTicketOption={selectedTicketOption}
                  getAmount={getAmount}
                  updateTicketOption={updateTicketOption}
                  selectedTicketQuantityOption={selectedTicketQuantityOption}
                  ticketQuantityOptions={ticketQuantityOptions}
                />

                {applePay && (
                  <div className="col-xl-6">
                    <div className="form-group">
                      <label htmlFor="ApplePay">Payment Method:</label>
                      <div className="input-group">
                        <ApplePayButton handleClick={handleRealButtonClick} />
                      </div>
                    </div>
                  </div>
                )}

                {googlePay && (
                  <div className="col-xl-6">
                    <div className="form-group">
                      {renderGooglePaymentMethodLabel()}

                      <div className="input-group">
                        <GooglePayButton handleClick={handleRealButtonClick} />
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-xl-6">
                  <div className="form-group">
                    {renderCreditCardPaymentMethodLabel()}

                    <div className="input-group">
                      <CreditCardPayButton
                        handleClick={(e) => {
                          e.preventDefault();
                          setCreditCard(true);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-12"
                  style={{
                    textAlign: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      color: "#54585a",
                      backgroundColor: "#efefef",
                    }}
                  >
                    <hr style={{ borderTop: "1px solid #54585a", flex: "1" }} />
                  </div>
                </div>

                <p style={{ color: "black" }}>
                  <b>Ticket Packages:</b> 1 for $25, 3 for $50, 10 for $100
                </p>
                <p style={{ color: "black" }}>
                  <b>Maximum tickets being made available for sale:</b> 1550
                </p>
                <p style={{ color: "black" }}>
                  <b>Date(s), time(s), and location of the draw(s):</b>{" "}
                  <b>"WestJet Draw":</b> October 21, 2024 at 12:00 PM
                </p>
                <p style={{ color: "black" }}>
                  200 - 5118 Joyce Street, Vancouver, BC, V5R 4H1 - this will be
                  streamed online. Link to draw will be sent with raffle ticket
                  sales.
                </p>
                <p style={{ color: "black" }}>
                  <b>METHOD:</b> Only 1 draw where winner will receive the
                  physical prize. The winner will be determined by a random draw
                  utilizing an GPEB registered Electronic Raffle Supplier (ERS)
                  Random Number Generator (RNG). The winner shall be announced
                  to all participants via electronic notification.
                </p>
                <p style={{ color: "black" }}>
                  <b>ELIMINATION OF WINNERS:</b> There's only one draw to be
                  conducted, no subsequent draws after that.
                </p>
                <p style={{ color: "black" }}>
                  <b>AGE LIMIT:</b> Must be 19 or older to participate.
                </p>
                <p style={{ color: "black" }}>
                  <b>CONDITIONS & ELIGIBILITY:</b> Participants must be located
                  within the province of British Columbia. The following
                  individuals are not eligible to participate in the raffle: All
                  employees of the organization associated with the management
                  and execution of the raffle, and all of the organization's
                  board members.
                </p>
                <p style={{ color: "black" }}>
                  <b>ANY OTHER RESTRICTIONS TO THE WINNING PRIZE:</b> The
                  organization is not responsible for difficulties arising in
                  contacting the winner due to the winner entering erroneous
                  contact information at the time of purchase. If a person
                  believes they are a winner and haven't been contacted by
                  organization staff, please email mweinstein@bigsisters.bc.ca
                  or call 6048734525.
                </p>
                <p style={{ color: "black" }}>
                  <b>
                    Winners are not required to be present at the draw location.
                  </b>
                </p>
                <p style={{ color: "black" }}>
                  <b>CONDITIONS FOR UNCLAIMED PRIZES:</b> Winners have 30 days
                  to claim the prize. Unclaimed prizes will be donated to the
                  foundation approved by GPEB.
                </p>
                <p style={{ color: "black" }}>
                  <b>PURCHASING TICKETS:</b> Tickets can only be purchased
                  online.
                </p>
                <p style={{ color: "black" }}>
                  <b>HOW TO CONTACT ORGANIZATION:</b> To contact Big Sisters of
                  BC Lower Mainland please email mweinstein@bigsisters.bc.ca or
                  call 6048734525.
                </p>
              </>
            ) : (
              <>
                <TicketNameForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                  creditCardSectionTitle={
                    applePay || googlePay
                      ? "Or Credit Card Payment"
                      : "Credit Card Payment"
                  }
                  setCreditCard={setCreditCard}
                />

                <ContactForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                />

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="cardNumber">
                      Pay by Credit Card <span className="required">*</span>
                    </label>
                    <div className="input-group">
                      <CardElement
                        className="form-control"
                        options={CARD_ELEMENT_OPTIONS}
                      />
                    </div>
                  </div>
                </div>

                <AddressForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                />

                <BillingForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                  handleProvinceChange={handleProvinceChange}
                  toggleValue={toggleValue}
                  handleCountryChange={handleCountryChange}
                  handleBillingProvinceChange={handleBillingProvinceChange}
                  handleBillingCountryChange={handleBillingCountryChange}
                />

                <button className="btn btn-theme effect btn-md" type="submit">
                  Buy Tickets Now
                </button>

                <div className="question">
                  <div className="icon">
                    <i className="fas fa-phone"></i>
                  </div>

                  <div className="info">
                    <h5 style={{ color: "rgb(45, 204, 211)" }}>
                      Have any questions about your Soirée 2024 ticket(s)
                      purchase?
                    </h5>
                    <span style={{ color: "#232323" }}>
                      Call Now: 604.873.4525 ext 108
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default BuyTicketsForm;