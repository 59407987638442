// ticketService.js
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/ticket-mode`;
let cachedMode = null;

const getTicketMode = async () => {
    if (cachedMode) {
        return cachedMode;
    }

    try {
        const response = await axios.get(API_URL);
        cachedMode = response.data.mode;
        return cachedMode;
    } catch (error) {
        console.error('Error fetching ticket mode:', error);
        throw error;
    }
};

export default getTicketMode;
