const generateQuantityOptions = (max) => {
    const options = [];
    for (let i = 1; i <= max; i++) {
      options.push({ value: i, label: i.toString() });
    }
    return options;
  };
  
  const quantityOptions = generateQuantityOptions(1000);

  export default quantityOptions;