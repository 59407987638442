import React from "react";
import { formatAsCurrency } from "../services/formUtils";
import CustomChoiceButtons from "./CustomChoiceButtons"

class TicketForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      options,
      ticketQty,
      selectedTicketOption,
      updateTicketQuantity,
      getAmount,
      updateTicketOption,
      selectedTicketQuantityOption,
      ticketQuantityOptions,
      handleInputChange,
      formData
    } = this.props; // Destructure props

    return (
      <>
        <CustomChoiceButtons
          value={selectedTicketOption}
          handleChoiceUpdate={updateTicketOption}
          label={"Raffle Set Option"}
          identifier="selectedTicketOption"
          choiceOptions={options}
          defaultValue={selectedTicketOption}
          isRequired={true}
        />


        {(selectedTicketOption.value === "corporate" || selectedTicketOption.value === "table") && <>
        <div className="col-md-12">
            <div className="d-flex flex-wrap align-items-center" style={{marginLeft: "13px"}}>
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="ticketInfo"
              >
                &nbsp;&nbsp;10 tickets per table
              </label>
            </div>
        </div>
        </>}

        <div className="col-md-12">
            <div className="d-flex flex-wrap align-items-center" style={{marginLeft: "13px"}}>
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="coverFeeOption"
              >
                &nbsp;&nbsp;Your ticket subtotal is:&nbsp;
                <span style={{ fontWeight: "bold" }}>
                  {formatAsCurrency(getAmount(selectedTicketQuantityOption, selectedTicketOption))}
                </span>
                &nbsp;
              </label>
            </div>
        </div>

      </>
    );
  }
}

export default TicketForm;
