import React from 'react';
import '../assets/css/Footer.css'; // Import your CSS file for styling

const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundColor: '#2dccd3', padding: '20px', color: 'white' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
          <h4 style={{ textAlign: 'left' }}>QUICKLINKS</h4>
            <a href="https://www.bigsisters.bc.ca/private-policy/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', textAlign: 'left', display: 'block' }}>
              Privacy Policy
            </a>
          </div>

          <div className="col-md-4">
            <h4 style={{ textAlign: 'left' }}>TO REACH US</h4>
            <p style={{ textAlign: 'left' }}>
              Big Sisters of BC Lower Mainland<br />
              Suite 200, 5118 Joyce Street<br />
              Vancouver, BC V5R 4H1
            </p>
            <p style={{ textAlign: 'left' }}>
              Phone: 604-873-4525<br />
              Email: <a href="mailto:info@bigsisters.bc.ca" style={{ color: '#fff' }}>info@bigsisters.bc.ca</a><br />
              Charitable Business Number: 11880 9409 RR 0001
            </p>
          </div>

          <div className="col-md-4">
            <h4 style={{ textAlign: 'left' }}>COPYRIGHTS</h4>
            <p style={{ textAlign: 'left' }}>
              © 2021, Big Sisters of BC Lower Mainland - BSBCLM Charitable Business Number: 11880 9409 RR 0001
            </p>
            <p style={{ textAlign: 'left' }}>
              At Big Sisters, we honour that the land on which we work and mentor is the traditional and unceded territory of the 
              xʷməθkʷəy̓əm (Musqueam), Sḵwx̱wú7mesh Úxwumixw (Squamish), səl̓ilw̓ətaʔɬ (Tsleil-Waututh), QayQayt First Nation, 
              Kwantlen, q̓íc̓əy̓ (Katzie), Semiahmoo, Tsawwassen First Nations, kʷikʷəƛ̓əm (Kwikwetlem), and Stó:lō Nation.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;