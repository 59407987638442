import React from "react";
import PropTypes from "prop-types";
import "../assets/css/CustomChoiceButtons.css";

class CustomChoiceButtons extends React.Component {
  render() {
    const {
      value,
      handleChoiceUpdate,
      label,
      identifier,
      outerClass,
      isRequired,
      choiceOptions,
    } = this.props;

    return (
      <div className={outerClass ? outerClass : "col-md-12"}>
        <div className="form-group">
          <label htmlFor={identifier}>
            {label}
            {isRequired && <span className="required"> *</span>}
          </label>
          <div className="btn-group" role="group" aria-label={label}>
            {choiceOptions.map((option) => (
              <button
                key={option.value}
                type="button"
                className={`btn choice-btn ${
                  value.value === option.value ? "btn-primary" : "btn-secondary"
                }`}
                onClick={() => handleChoiceUpdate(option, identifier)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

CustomChoiceButtons.propTypes = {
  value: PropTypes.any.isRequired,
  handleChoiceUpdate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  outerClass: PropTypes.string,
  isRequired: PropTypes.bool,
  choiceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CustomChoiceButtons;