import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes } from "react-router-dom";

import BuyTicketsComponent from './components/BuyTicketsComponent';


function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BuyTicketsComponent />} />
        <Route path="*" element={<BuyTicketsComponent />} /> {/* Fallback route */}

      </Routes>
    </div>
  );
}

export default App;
