import React, { Component } from "react";
import "../assets/css/DonationComponent.css";
import "../assets/css/responsive.css";
import StripeContainer from "./StripeContainer";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar";
import Footer from "./Footer";
import TicketThankYouPage from "./TicketThankYouPage";
import BlueSection from "./BlueSection";
import { ReactComponent as BackgroundSVG } from "../assets/img/background2.svg";
import getTicketOptions from "./ticketOptions";
import quantityOptions from "./generateTicketService";
import westjet from "../assets/img/westjet.png";

const ticketOptions = await getTicketOptions();

class BuyTicketsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentURL: "",
      hasDonated: false,
      selectedTicketQuantityOption: quantityOptions[0],
      selectedTicketOption: ticketOptions[0],
      payFees: true,
      firstName: "",
      ticketOptions: ticketOptions,
      ticketQuantityOptions: quantityOptions,
      registrationURL: "",
      isMobile: false,
      isSuperMobile: window.innerWidth < 767, // Set initial value based on window width
      raffleNumbers: []
    };
  }

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };

  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  updateRaffleNumbers = (raffleNumbers) => {
    this.setState({ raffleNumbers });
  };

  togglePayFees = (e) => {
    this.setState({ payFees: !this.state.payFees });
  };

  componentDidMount() {
    this.setState({ currentURL: window.location.href });
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize(); // Call initially to set state based on screen size
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateIsMobile);
  }

  handleWindowResize = () => {
    // Check if screen width is less than or equal to 768 pixels (typical mobile width)
    const isMobile = window.innerWidth <= 990;
    this.setState({ isMobile: isMobile }); // Display on desktop, hide on mobile
  };


  updateFirstName = (firstName) => {
    this.setState({ firstName: firstName });
  };

  updateTicketOption = (option, identifier) => {
    if (identifier === "selectedTicketOption") {
      let selectedTicketQuantityOption =
        this.state.selectedTicketQuantityOption;

      if (
        option.value.includes("table") ||
        option.value.includes("corporate")
      ) {
        selectedTicketQuantityOption = quantityOptions[0];
      }

      this.setState({
        [identifier]: option,
        selectedTicketQuantityOption: selectedTicketQuantityOption,
      });
    } else {
      this.setState({ [identifier]: option });
    }
  };

  updateRegistrationURL = (value) => {
    this.setState({ registrationURL: value });
  };

  getWestJetComponent = (isMobile) => {
    let classes = "col-lg-6 info"
    if (isMobile) {
      classes = "col-lg-6 info mt-1"
    }

    return <div className={classes}>
    <form className="title westjet-info">
      <p>
        <img src={westjet} alt="WestJet" />
      </p>
    </form>
  </div>;
  }

  getMainForm = () => {

    return (
      <div className={"col-lg-6 info text-light donation-form"}>
      {this.state.hasDonated ? (
        <TicketThankYouPage
          selectedTicketQuantityOption={
            this.state.selectedTicketQuantityOption
          }
          selectedTicketOption={this.state.selectedTicketOption}
          registrationURL={this.state.registrationURL}
          updateHasDonated={this.updateHasDonated}
          firstName={this.state.firstName}
          raffleNumbers={this.state.raffleNumbers}
        />
      ) : (
        <StripeContainer
          updateIsLoading={this.updateIsLoading}
          updateHasDonated={this.updateHasDonated}
          updateAmount={this.updateAmount}
          payFees={this.state.payFees}
          togglePayFees={this.togglePayFees}
          updateFirstName={this.updateFirstName}
          formType="BuyTickets"
          ticketOptions={this.state.ticketOptions}
          updateTicketOption={this.updateTicketOption}
          selectedTicketOption={this.state.selectedTicketOption}
          updateRegistrationURL={this.updateRegistrationURL}
          selectedTicketQuantityOption={
            this.state.selectedTicketQuantityOption
          }
          ticketQuantityOptions={this.state.ticketQuantityOptions}
          updateRaffleNumbers={this.updateRaffleNumbers}
        />
      )}
    </div>

    )

  }

  render() {
   const {isMobile} = this.state;

    return (
      <>
        <BlueSection />
        <div className="donation-area bg-gray default-padding">
          {/* Fixed BG */}
          <Navbar />
          <div
            className={
              this.state.currentURL.includes("monthly")
                ? "fixed-bg monthly"
                : "fixed-bg"
            }
            id="bg-wrap"
          >
            <BackgroundSVG />
          </div>
          {/* End Fixed BG */}
          <div className="container">
            <div className={"row"}>
              {!isMobile ? <>

              {this.getWestJetComponent(isMobile)}
              {this.getMainForm()}
              
              </> : <>
              {this.getMainForm()}
              {this.getWestJetComponent(isMobile)}

              </>

              }


            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default BuyTicketsComponent;