import getTicketMode from './ticketService'

const early_text = "2024 Early Bird Tickets";

const regular_text = "";

const getTicketLabel = async () => {
    const mode = await getTicketMode();

    if (mode === "EARLY") {
        return early_text;
    }

    return regular_text;
};

export default getTicketLabel;
