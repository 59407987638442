export const countryOptions = [
    { value: "CAN", label: "Canada" },
    { value: "USA", label: "United States" },
  ];
  
  export const stateOptions = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
  ];
  
  export const provinceOptions = [
    { value: "BC", label: "British Columbia" },
    { value: "AB", label: "Alberta" },
    { value: "MB", label: "Manitoba" },
    { value: "NB", label: "New Brunswick" },
    { value: "NL", label: "Newfoundland and Labrador" },
    { value: "NS", label: "Nova Scotia" },
    { value: "ON", label: "Ontario" },
    { value: "PE", label: "Prince Edward Island" },
    { value: "QC", label: "Quebec" },
    { value: "SK", label: "Saskatchewan" },
    { value: "NT", label: "Northwest Territories" },
    { value: "NU", label: "Nunavut" },
    { value: "YT", label: "Yukon" },
  ];
  
  export const wordingOptions = [
    { value: "Honor", label: "In Honour of" },
    { value: "Memory", label: "In Memory of" },
  
  ];
  
  export const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    selectedProvince: provinceOptions[0],
    selectedCountry: countryOptions[0],
    provinceLabel: "Province",
    provinceOptions: provinceOptions,
    zip: "",
    zipLabel: "Postal Code",
    monthly: false,
    tribute: false,
    anonymous: false,
    organization: false,
    notify: false,
    organizationName: "",
    selectedWording: wordingOptions[0],
    tributeFirstName: "",
    tributeLastName: "",
      notifyFirstName: "",
      notifyLastName: "",
      notifyAddress: "",
      notifyCountry: "",
      notifyPostalCode: "",
      notifyCity: "",
      campaign: "",
      appeal: "",
      fund: "",
      receipt: "",
      notifyNote: "",
      notifyContactOptions: "mail",
      notifyEmail: "",
      notifyPhoneNumber: "",
      billingAddress: true,
      billingAddressLine1: "",
      billingAddressLine2: "",
      billingCity: "",
      billingSelectedProvince: provinceOptions[0],
      billingSelectedCountry: countryOptions[0],
      billingProvinceLabel: "Province",
      billingProvinceOptions: provinceOptions,
      billingZip: "",
      billingZipLabel: "Postal Code",
      payFees: true,
      behalf: false,
      ecard: false,
      behalfImageOption: "",
      behalfNote: "",
      behalfFirstName: "",
      behalfLastName: "",
      behalfEmail: "",
      behalfPhoneNumber: "",
      ticketQty: 0,
      ticketTypeOption: "regular",
      attendEvent: true,
      voucher: "",
      saveInfo: false
  }
  
  export const monthlyInitialState = {
    amount: 100,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    selectedProvince: provinceOptions[0],
    selectedCountry: countryOptions[0],
    provinceLabel: "Province",
    provinceOptions: provinceOptions,
    zip: "",
    zipLabel: "Postal Code",
    monthly: true,
    tribute: false,
    anonymous: false,
    organization: false,
    notify: false,
    organizationName: "",
    selectedWording: wordingOptions[0],
    tributeFirstName: "",
    tributeLastName: "",
    notifyFirstName: "",
    notifyLastName: "",
    notifyAddress: "",
    notifyCity: "",
    notifyCountry: "",
    notifyPostalCode: "",
    notifyPhoneNumber: "",
    campaign: "",
    appeal: "",
    fund: "",
    receipt: "",
    notifyNote: "",
    notifyContactOptions: "mail",
    notifyEmail: "",
    billingAddress: true,
    billingAddressLine1: "",
    billingAddressLine2: "",
    billingCity: "",
    billingSelectedProvince: provinceOptions[0],
    billingSelectedCountry: countryOptions[0],
    billingProvinceLabel: "Province",
    billingProvinceOptions: provinceOptions,
    billingZip: "",
    billingZipLabel: "Postal Code",
    behalf: false,
    ecard: false,
    behalfImageOption: "",
    behalfNote: "",
    behalfFirstName: "",
    behalfLastName: "",
    behalfEmail: "",
    behalfPhoneNumber: "",
    ticketQty: 0,
    ticketTypeOption: "regular",
    attendEvent: true,
    voucher: "",
    saveInfo: false
  }

  export const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#232323",
        fontSize: "16px",
      },
      invalid: {
        color: "#fa755a",
        fontSize: "16px",
      },
    },
  };
  
export const DROPDOWN_STYLE = {
    control: (baseStyles, state) => {
      return {
        ...baseStyles,
        borderWidth: "0px",
        minHeight: "0px",
        boxShadow: "0px",
      };
    },
    menu: (baseStyles, state) => {
      return {
        ...baseStyles,
        marginTop: "0px",
        marginBottom: "0px",
        boxShadow: "0px",
        fontSize: "0.9rem",
      };
    },
    option: (baseStyles, state) => {
      return {
        ...baseStyles,
        backgroundColor: state.isFocused ? "#DEEBFF" : "white",
        color: "inherit",
      };
    },
  };


  export const SEARCH_DROPDOWN_STYLE = {
    control: (baseStyles, state) => {
      return {
        ...baseStyles,
        borderWidth: "1px",
        borderColor: "#e7e7e7",
        minHeight: "0px",
        boxShadow: "0px"
      };
    },
    menu: (baseStyles, state) => {
      return {
        ...baseStyles,
        marginTop: "0px",
        marginBottom: "0px",
        boxShadow: "0px",
        fontSize: "0.9rem",
      };
    },
    option: (baseStyles, state) => {
      return {
        ...baseStyles,
        backgroundColor: state.isFocused ? "#DEEBFF" : "white",
        color: "black",
      };
    },
  };

  export const VOUCHERS = [
    'YP001', 'YP002', 'YP003', 'YP004', 'YP005', 
    'YP006', 'YP007', 'YP008', 'YP009', 'YP010', 
    'YP011', 'YP012', 'YP013', 'YP014', 'YP015', 
    'YP016', 'YP017', 'YP018', 'YP019', 'YP020'
  ];