import {
    stateOptions,
    provinceOptions
  } from "../components/constants";
  import { Tooltip } from "react-bootstrap";

export const handleCountryChange = (selectedOption, formData, setFormData) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";
  
    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }
  
    const updatedState = {
      selectedCountry: selectedOption,
    };
  
    if (newLabel !== formData.provinceLabel) {
      updatedState.provinceLabel = newLabel;
      updatedState.provinceOptions = options;
      updatedState.selectedProvince = options[0];
      updatedState.zipLabel = newZipLabel;
    }
  
    setFormData({ ...formData, ...updatedState });
  };
  


  export const handleBillingCountryChange = (selectedOption, formData, setFormData) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      billingSelectedCountry: selectedOption,
    };

    if (newLabel !== formData.billingProvinceLabel) {
      updatedState.billingProvinceLabel = newLabel;
      updatedState.billingProvinceOptions = options;
      updatedState.billingSelectedProvince = options[0];
      updatedState.billingZipLabel = newZipLabel;
    }

    setFormData({ ...formData, ...updatedState });
  };

  export const tooltip = (
    <Tooltip id="tooltip">
      By covering the transaction fee, Big Sisters of BC Lower Mainland will keep 100% of your purchase.
    </Tooltip>
  );

  export const saveInfoTooltip = (
    <Tooltip id="saveInfoTooltip">
      Enable this option to securely save your credit card information for quick and easy checkout during our Big Sisters’ Soirée event. Your card details will be encrypted and stored with the highest security standards, ensuring your data is protected. This will allow you to participate in other activities without having to re-enter your card information each time.    </Tooltip>
  );


  export const formatAsCurrency = (amount) => {
    // Assuming amount is a number
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }