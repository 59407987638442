import React, { Component } from "react";
import "../assets/css/ThankYouPage.css";

class TicketThankYouPage extends Component {
  handleDonateAgain = (e) => {
    e.preventDefault();

    this.props.updateHasDonated(false);
  };

  handleContinue = (e) => {
    e.preventDefault();
    window.location.href = `/`; // Redirect to Big Sisters website
  };

  render() {
    const { firstName, raffleNumbers } = this.props;

    const cleanedRaffleNumbers = raffleNumbers.map(number =>
      number.replace('Raffle_Number', '').trim()
    );

    return (
        <div className="form-box thank-you-page">
          <form>
            <h3 style={{ color: "#2dccd3" }}>
              {firstName ? (
                <>Thank You, {firstName}!</>
              ) : (
                <>Thank You!</>
              )}
            </h3>
      
              <p style={{ color: "#232323", fontWeight: "bold" }}>
                Check your inbox for your purchase confirmation!
              </p>



            <p style={{ color: "#232323" }}>
              Supporters like you make it possible for Big Sisters to bring
              mentorship programs to girls and gender-diverse youth in our community. Thank you
              for helping to ignite the potential of youth across the Lower
              Mainland.
            </p>

            <p style={{ color: "#232323" }} className="message-well">
              <h5 style={{ color: "rgb(45, 204, 211)" }}>Your Raffle Numbers:</h5>
              <ul>
                {cleanedRaffleNumbers.map((number, index) => (
                  <li key={index}>{number}</li>
                ))}
              </ul>

            </p>

                <button
                  className="btn btn-theme effect btn-md donate-again"
                  type="submit"
                  onClick={this.handleContinue}
                >
                  Buy More Raffle Tickets
                </button>
          </form>
        </div>
    );
  }
}

export default TicketThankYouPage;