import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BuyTicketsForm from "./BuyTicketsForm";

console.log(process.env.REACT_APP_STRIPE_KEY);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeContainer = ({
  updateIsLoading,
  updateHasDonated,
  amount,
  updateAmount,
  formType,
  payFees,
  togglePayFees,
  updateFirstName,
  ticketOptions,
  updateTicketOption,
  selectedTicketOption,
  updateRegistrationURL,
  selectedTicketQuantityOption,
  ticketQuantityOptions,
  updateRaffleNumbers
}) => {

  // Render different types of DonationForms based on the 'formType' prop
  const renderDonationForm = () => {
        return (
          <BuyTicketsForm
            updateIsLoading={updateIsLoading}
            updateHasDonated={updateHasDonated}
            amount={amount}
            updateAmount={updateAmount}
            payFees={payFees}
            togglePayFees={togglePayFees}
            updateFirstName={updateFirstName}
            ticketOptions={ticketOptions}
            updateTicketOption={updateTicketOption}
            selectedTicketOption={selectedTicketOption}
            selectedTicketQuantityOption={selectedTicketQuantityOption}
            updateRegistrationURL={updateRegistrationURL}
            ticketQuantityOptions={ticketQuantityOptions}
            updateRaffleNumbers={updateRaffleNumbers}
          />
        );

    };

  return <Elements stripe={stripePromise}>{renderDonationForm()}</Elements>;
};

export default StripeContainer;
