import React from 'react';
import '../assets/css/FakeGooglePayButton.css'; // Import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';

const CreditCardPayButton = ({handleClick}) => {

  return (
    <div className="fake-google-pay-button" onClick={handleClick}>
      <div alt="Credit Card Pay" className="credit-card-pay-logo" > 
      <FontAwesomeIcon icon={faCreditCard} className="credit-card-icon" />{" "}
        Credit Card
      </div>
 
    </div>
  );
};

export default CreditCardPayButton;
