import React, { Component } from 'react';
import '../assets/css/Navbar.css'; // Import your CSS file for styling
import Logo from '../assets/img/logo.png'
import MobileLogo from '../assets/img/mobile-logo.png'

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    if (isScrolled !== this.state.scrolled) {
      this.setState({ scrolled: isScrolled });
    }
  };

  render() {
    const { scrolled } = this.state;
    return (
      <>
            <img className="mobile-logo" src={Logo} alt="Logo" />
      <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className='container'>
        <div className="logo">
          <img className="desktop-logo" src={Logo} alt="Logo" />
          
        </div>

        </div>
      </nav>
      </>

    );
  }
}

export default Navbar;
